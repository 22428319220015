import React, { useMemo } from "react";

import styled from "@emotion/styled";
import shallow from "zustand/shallow";

import { navigate } from "../../../../../hooks/navigation";
import Headline from "../../../../base/atoms/Headline";
import Container from "../../../../shared/Container";
import ReactHookInput from "../../../../shared/forms/ReactHookInput";
import Button from "../../../../shared/NewForms/Button";
import useStore, { useLiquidGasInquiryForm } from "./store";

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 20px;

    & > button {
        max-width: 400px;
    }
`;

const LiquidGasQuestionnaire = () => {
    const {
        currentQuestionnaireStep,
        prevQuestionnaireStep,
        nextQuestionnaireStep,
        questions,
    } = useStore(
        ({
            currentQuestionnaireStep,
            prevQuestionnaireStep,
            nextQuestionnaireStep,
            questions,
        }) => ({
            currentQuestionnaireStep,
            prevQuestionnaireStep,
            nextQuestionnaireStep,
            questions,
        }),
        shallow
    );

    const currentQuestion = useMemo(
        () => questions[currentQuestionnaireStep],
        [questions, currentQuestionnaireStep]
    );

    const {
        control,
        formState: { errors },
        handleSubmit,
        register,
    } = useLiquidGasInquiryForm();

    const goToNext = () => {
        if (currentQuestionnaireStep === questions.length - 1) {
            void navigate("/fluessiggas/anfrage/zusammenfassung");
        } else {
            nextQuestionnaireStep();
        }
    };

    const goToPrevious = () => {
        if (currentQuestionnaireStep === 0) {
            void navigate("/fluessiggas/anfrage/");
        } else {
            prevQuestionnaireStep();
        }
    };

    if (!currentQuestion) return null;

    return (
        <>
            <form onSubmit={handleSubmit(goToNext)}>
                <Headline level={2}>Angaben zum Bedarf</Headline>
                <Container>
                    <p>{currentQuestion.text}</p>

                    {currentQuestion.type === "options" ? (
                        currentQuestion.options.map((o, i) => (
                            <div key={i}>
                                <label>
                                    <input
                                        key={`${currentQuestion.name}-${i}`}
                                        {...register(currentQuestion.name)}
                                        style={{
                                            accentColor: "darkblue",
                                        }}
                                        type="radio"
                                        value={o.value}
                                    />
                                    <span style={{ marginLeft: "10px" }}>
                                        {o.text}
                                    </span>
                                </label>
                            </div>
                        ))
                    ) : currentQuestion.type === "text" ? (
                        <ReactHookInput
                            key={currentQuestion.name}
                            name={currentQuestion.name}
                            control={control}
                            placeholder={currentQuestion.placeholder ?? ""}
                            rules={{
                                required: true,
                            }}
                        />
                    ) : (
                        (null as never)
                    )}

                    <ButtonWrapper>
                        <Button
                            color="grey"
                            type="button"
                            onClick={goToPrevious}
                        >
                            Zurück
                        </Button>

                        <Button
                            color="red"
                            type="submit"
                            disabled={questions
                                .slice(currentQuestionnaireStep)
                                .every((q) => q == null)}
                        >
                            Weiter
                        </Button>
                    </ButtonWrapper>
                </Container>
            </form>
        </>
    );
};

export default LiquidGasQuestionnaire;
